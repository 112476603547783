import * as OfflinePluginRuntime from 'offline-plugin/runtime';

import './index.html';
import './index.scss';
import './scripts/script';
import slick from 'slick-carousel';
import './scripts/home';
import './scripts/contact';

OfflinePluginRuntime.install();
