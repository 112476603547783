import lozad from 'lozad';

const observer = lozad();
observer.observe();

let currentCarousel = 1;

$('.carousel-wrapper').each(function () {
  $(this).slick({
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    fade: true,
    autoplaySpeed: 5000,
    speed: 1000,
    dots: true,
    appendDots: $(this).siblings('.carousel-thumbs')
  });
});

$('.slick-dots li button').each(function () {
  let imgSource = $(this).attr('id');
  imgSource = imgSource.slice(-2);
  let imgURL = $(`#slick-slide${imgSource} img`).attr('src');
  $(this).css('background-image', `url(${imgURL})`);
})

$('.carousel-cta').on('click', function (e) {
  e.preventDefault();
  if ($(this).hasClass('active')) {
    return false;
  } else {
    let targetSolution = this.dataset.solution;
    $('.carousel-container .slick-slider').slick('slickGoTo', targetSolution);
    $('.carousel-cta').removeClass('active');
    $(this).addClass('active');
  }
});

$('.carousel-container .slick-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
  $('.carousel-cta').removeClass('active');
  $(".carousel-cta[data-solution='" + nextSlide + "']").addClass('active');
});



//Testimonials slider
$('.carousel-testimonials').slick({
  autoplay: true,
  arrows: true,
  autoPlaySpeed: 5000,
  prevArrow: '.arrow.prev',
  nextArrow: '.arrow.next',
  centerMode: false,
  slidestoShow: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        adaptiveHeight: true,
        centerMode: false
      }
    }
  ]
});