import Bouncer from 'formbouncerjs';

var validate = new Bouncer('.validate', {
  disableSubmit: true
});

// Get the form.
var form = $('#ajax-contact');

// Get the messages div.
var successMessage = $('.success-message');

// Set up an event listener for the contact form.
document.addEventListener('bouncerFormValid', function (event) {
 
  // Serialize the form data.
  var formData = $(form).serialize();
  $.ajax({
    method: 'POST',
    url: 'https://formsubmit.co/ajax/sales@uspropack.com',
    data: formData,
    datatype: 'json'
  })
  .done(function (response) {
    // Make sure that the formMessages div has the 'success' class.
    $(successMessage).addClass('success');

    // Set the message text.
    $(successMessage).text('Thank you for contacting us! We will be in touch shortly.');

    // Clear the form.
    $('.contact').slideUp('slow');
  })
  .fail(function (data) {
    // Make sure that the formMessages div has the 'error' class.
    $(successMessage).removeClass('success');
    $(successMessage).addClass('error');

    // Set the message text.
    if (data.responseText !== '') {
      $(successMessage).text(data.responseText);
    } else {
      $(successMessage).text(
        'Oops! An error occured and your message could not be sent.'
      );
    }
  });
})
